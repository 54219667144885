.errorContainer {
    text-align: center;
    margin-top: 10vh;
}

.errorIcon {
    font-size: 10vw;
    color: red;
}

.errorText {
    margin-top: 5%;
    font-size: 5vw;
    color: red
}

.helpText {
    margin-top: 5%;
    font-size: 4vw;
    color: black;
}

@media screen and (min-width: 700px) {
    .errorIcon {
       font-size: 7vw;
    }

    .errorText {
        font-size: 3vw;
    }

    .helpText {
        font-size: 2vw;
        width: 55%;
        margin: 2% auto;
    }
}
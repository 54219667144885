@media print {
  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }

  .headerBar {
    display: none !important;
  }

  .photos-section-print,
  .inspection-section-print {
    page-break-before: always !important;
    // break-before: always !important;
  }

  img {
    // page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    // page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    break-inside: avoid !important; /* or 'auto' */
  }
}

@primary-color: #265AB1;
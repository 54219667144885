.tabRow {
  display: "flex";
  flex-direction: row;
  padding: 2.35vw;
  padding-left: 0;
}

.tabRow__tab {
  font-weight: bold;
  font-size: 2.35vw;
  padding: 2.35vw;
  height: 100%;
  padding-bottom: 10%;
}

@media screen and (max-width: 475px) {
  .tabRow__tab {
    padding-bottom: 11.5%;
  }
}

@media screen and (max-width: 300px) {
  .tabRow__tab {
    padding-bottom: 12.5%;
  }
}

.tabRow__tab:hover {
  cursor: pointer;
}

.tabRow__tab--active {
  color: #2d6ac4;
  border-bottom: 0.4vw solid #2d6ac4;
  transition-property: color, border-bottom;
  transition-duration: 1s;
}

.tabRow__tab--inactive {
  color: black;
  transition-property: color;
  transition-duration: 1s;
  border-bottom: 0.2vw solid transparent;
}

.tabRow__tab--inactive:hover {
  color: #2d6ac4;
  transition-property: color;
  transition-duration: 1s;
}

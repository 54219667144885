img.photo-grid-item {
    width: 31.33%;
    padding: 1%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    object-fit: contain;
}

img.photo-grid-item-hidden {
    width: 31.33%;
    padding: 1%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    object-fit: contain;
    visibility: hidden;
}
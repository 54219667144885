:root {
  --none: rgba(0, 178, 0, 0.5);
  --scratch: rgba(128, 128, 0, 0.5);
  --crack: rgba(255, 215, 0, 0.5);
  --dent: rgba(255, 165, 0, 0.5);
  --gouge: rgba(255, 0, 0, 0.5);
  --thin-silver-border: 0.01vw solid silver;
  --table-border-color: rgba(77, 77, 77, 0.75);
  --table-label-background: rgba(240, 240, 240, 0.75);
  --table-data-background: white;
  --info-box-background: white;
  --info-box-border: 0.02vw solid silver;
  --silver-border: 0.2vw solid silver;
  --normal-font-size: 2vw;
}

/* BEGIN DAMAGE CHIP STYLES */

.damage-chip {
  border-radius: 100%;
  color: white;
  padding: 0.25em 0.75em;
}

.gouge {
  background-color: rgba(255, 0, 0, 1);
}

.dent {
  background-color: rgba(255, 165, 0, 1);
}

.crack {
  background-color: rgba(255, 215, 0, 1);
}

.scratch {
  background-color: rgba(128, 128, 0, 1);
}

/* END DAMAGE CHIP STYLES */

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

body {
  padding: 2%;
  font-family: "Times New Roman";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section-container {
  /* margin-top: .15vw; */
  background-color: rgba(250, 250, 250, 1);
  border: var(--silver-border);
  /* outline: .2vw solid black; */
  padding: 2%;
  /* margin: -0.2vw; */
}

.section-container--extra-padding-bottom {
  padding-bottom: 2%;
}

.section-header {
  font-weight: bold;
  /* padding: 1%; */
  font-size: var(--normal-font-size);
  padding-bottom: 0.75em;
}

.section-header--extra-padding {
  padding: 2%;
}

.overflow {
  overflow: auto;
}

/* BEGIN HEADER STYLES */

.header {
  justify-content: space-between;
  align-items: center;
}

.header__address-line {
  font-size: 1.75vw;
  font-weight: bold;
  white-space: nowrap;
}

.header__data {
  color: black;
  font-weight: bold;
  font-size: var(--normal-font-size);
}

.header__label {
  color: black;
  font-weight: bold;
}

.header__left {
  width: 100%;
}

.header__right {
  align-items: center;
}

.header__subtitle {
  font-weight: bold;
  font-size: 1.5vw;
  margin-left: 2%;
}

.header__title {
  font-weight: bold;
  font-size: 3.5vw;
}

.logo-container {
  width: 10vw;
  height: auto;
  margin-right: 1vw;
}

/* END HEADER STYLES */

/* BEGIN INFO STYLES */

.info-box {
  /* margin: 0 4% 4% 4%; */
  /* margin: 0 1em 1em 1em; */
  font-size: 2.25vw;
  font-weight: 500;
  background-color: var(--info-box-background);
  border: var(--info-box-border);
  padding: 3%;
}

.info-box__label-text {
  font-weight: bold;
}

.info-grid {
  display: grid;
  /* grid-template-rows: repeat(2, 1fr); */
  grid-template-columns: repeat(2, 1fr);
  /* border: var(--silver-border); */
}

.info-grid-item {
  background-color: rgba(250, 250, 250, 1);
  border: var(--silver-border);
  padding: 4%;
  /* min-height: 45vw; signatures overflow otherwise */
}

.info-grid-item__header {
  font-weight: bold;
  /* margin: 2%; */
  font-size: var(--normal-font-size);
}

.info-table {
  border: 0.01vw 0 0 var(--thin-silver-border);
  /* margin: 0 4% 4% 4%; */
  /* margin: 0 1em 1em 1em; */
}

.info-table__col {
  text-align: center;
  width: 50%;
}

.info-table__item {
  font-size: 1.75vw;
  border: var(--thin-silver-border);
  /* margin: -.04vw; */
  /* margin: -.01vw 0 0 -.01vw; */
  /* padding: 3% */
  padding: 0.45em;
  height: 2vw;
  /* width: 100%; */
}

@media screen and (max-width: 500px) {
  .info-table__item {
    font-size: 1.25vw;
  }
}

.info-table__item--data {
  color: black;
  font-weight: 500;
  background-color: var(--table-data-background);
}

.info-table__item--label {
  color: var(--table-border-color);
  background-color: var(--table-label-background);
}

.margin-bottom {
  margin-bottom: 1em;
}

.signature-container {
  /* width: 100%; */
  height: 5vw;
  border: var(--thin-silver-border);
  background-color: white;
}

.signature-container img {
  object-fit: scale-down;
  /* width: 100%; */
  height: 100%;
  margin-left: 5%;
}

.signature-header {
  font-weight: bold;
  /* padding: 1%; */
  font-size: var(--normal-font-size);
  padding-bottom: 0.75em;
  padding-top: 0.75em;
}

/* END INFO STYLES */

/* BEGIN INSPECTION STYLES */

.damage-data {
  font-size: var(--normal-font-size);
  border: var(--thin-silver-border);
  text-align: center;
  padding: 0.25em;
}

.damage-table {
  /* margin: 0 5% 5% 5%; */
}

.damage-table__col--data {
  width: 90%;
}

.damage-table__col--labels {
  width: 10%;
}

.damage-table__item {
  font-size: var(--normal-font-size);
  border: var(--thin-silver-border);
  text-align: center;
  padding: 0.25em;
}

.damage-table__item--data {
  background-color: var(--table-data-background);
}

.damage-table__item--label {
  background-color: var(--table-label-background);
}

.diagram-container {
  width: 30vw;
  height: 30vw;
  position: relative;
}

.diagram-damage-table-container {
  width: 30vw;
  /* margin-top: 3%; */
  border: var(--silver-border);
}

.diagrams-grid {
  display: grid;
  /* grid-template-rows: repeat(2, 50%); */
  grid-template-columns: repeat(2, 1fr);
  gap: 5vw;
  /* padding: 0% 2% 2% 2%; */
}

.diagram-grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.diagram-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}

.diagram-overlay__row {
  width: 100%;
  height: 50%;
  position: relative;
}

.diagram-overlay__section {
  width: 50%;
  border: 0.15vw solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.diagram-overlay__section-number {
  text-align: center;
  font-size: 4vw;
}

.driver-notes-section {
  border: var(--silver-border);
  /* margin: 10% 5% 3% 5%; */
  /* position: relative;
    bottom: 50px; */
}

/* .damage-key {
  margin-top: 2%;
  margin-left: 33%;
  border: none;
}

.half-width {
  width: 50%;
} */

/* END INSPECTION STYLES */

/* BEGIN DIAGRAM COLORS */

.front-1 {
  background-color: var(--none);
}

.damaged {
  background-color: var(--gouge);
}

.front-2 {
  background-color: var(--none);
}

.front-3 {
  background-color: var(--none);
}

.front-4 {
  background-color: var(--none);
}

.driverside-1 {
  background-color: var(--none);
}

.driverside-2 {
  background-color: var(--none);
}

.driverside-3 {
  background-color: var(--none);
}

.driverside-4 {
  background-color: var(--none);
}

.driverside-5 {
  background-color: var(--none);
}

.driverside-6 {
  background-color: var(--none);
}

.driverside-7 {
  background-color: var(--none);
}

.driverside-8 {
  background-color: var(--none);
}

.rear-1 {
  background-color: var(--none);
}

.rear-2 {
  background-color: var(--none);
}

.rear-3 {
  background-color: var(--none);
}

.rear-4 {
  background-color: var(--none);
}

.passengerside-1 {
  background-color: var(--none);
}

.passengerside-2 {
  background-color: var(--none);
}

.passengerside-3 {
  background-color: var(--none);
}

.passengerside-4 {
  background-color: var(--none);
}

.passengerside-5 {
  background-color: var(--none);
}

.passengerside-6 {
  background-color: var(--none);
}

.passengerside-7 {
  background-color: var(--none);
}

.passengerside-8 {
  background-color: var(--none);
}

/* END DIAGRAM COLORS */

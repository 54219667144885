.photo-coordinate-text-hidden {
    visibility: hidden;
    display: inline-block;
    width: 31.33%;
    font-size: 1.25vw;
    text-align: end;
    padding: 1%;
    position: relative;
    bottom: 10px;
}

.photo-coordinate-text {
    display: inline-block;
    width: 31.33%;
    font-size: 1.25vw;
    text-align: end;
    padding: 1%;
    position: relative;
    /* bottom: 10px; */
    bottom: 1vw;
}

@media screen and (max-width: 636px) {
    .photo-coordinate-text-hidden, .photo-coordinate-text {
        bottom: 1.75vw;
    }
}

@media screen and (max-width: 485px) {
    .photo-coordinate-text-hidden, .photo-coordinate-text {
        bottom: 2.55vw;
    }
}

@media screen and (max-width: 385px) {
    .photo-coordinate-text-hidden, .photo-coordinate-text {
        bottom: 3.75vw;
    }
}
.loadingContainer {
    margin-top: 10vh;
    font-size: 4vw;
    text-align: center;
}

.loadingText {
    margin-top: 5%;
    color: #2d6ac4;
}

/* Override style of spinner container to center component */
.rai-spinner {
    margin: 0 auto;
}

@media screen and (min-width: 700px) {
    .loadingContainer {
       font-size: 2vw;
    }
}
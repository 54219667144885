.printerIcon {
    font-size: 5vw;
    color: 'black';
    transition-property: color;
    transition-duration: 1s;
}

.printerIcon:hover {
    cursor: pointer;
    color: #2d6ac4;
    transition-property: color;
    transition-duration: 1s;
}